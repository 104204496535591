import { Form, Input, Button, Row, Col, Typography } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import Api, { Rotas } from "../../../services/api";
import { IUser } from "../../../types/IUser";

interface ParamsProps {
  id: string;
}

export default function UsuarioNewEdit() {
  const history = useHistory();
  const params = useParams<ParamsProps>();
  const userId = params.id;
  const editing = !!userId;
  const { data, isLoading: isLoadingQuery } = useQuery<IUser[]>(
    Rotas["/usuario/pesquisar/:textSearch"].replace(":textSearch", userId)
  );
  const [loading, setLoading] = useState(false);

  async function onFinish(user: IUser) {
    try {
      setLoading(true);
      if (editing) {
        await Api.put(Rotas["/usuario/:id"].replace(":id", userId), {
          ...user,
          isFuncionario: true,
        });
      } else {
        await Api.post(Rotas["/usuario"], {
          ...user,
          isFuncionario: true,
        });
      }
      history.push("/usuarios");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const colConfig = {
    xs: 20,
    md: 12,
  };
  const { Title } = Typography;

  if (loading || isLoadingQuery) {
    return <Spinner />;
  }

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Usuário</Title>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col {...colConfig}>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{ ...(data ? data[0] : {}) }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Nome"
              name="nome"
              rules={[{ required: true, message: "Nome" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, type: "email", message: "E-mail" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={`Senha`}
              tooltip={
                editing
                  ? "(se não deseja alterar a senha, deixe em branco)"
                  : ""
              }
              name="senha"
              rules={[
                {
                  required: editing ? false : true,
                  message: "Deve ser maior que 6 dígitos",
                  min: 6,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
