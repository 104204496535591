import { Button, Col, Row, Space, Input, Radio, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import { showConfirmation } from "../../hooks/showConfirmation";
import Api, { Rotas } from "../../services/api";
import { DeleteOutlined, EditOutlined, SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ICooperado } from "../../types/ICooperado";
import moment from "moment";
import { SortableTable } from "../../components/SortableTable";
import { DEFAULT_DATE_FORMAT } from "../../components/DatePickerPtBr";
import NotificationModal from "../../components/NotificationModal";
import { useCallback, useState } from "react";
import "./index.css";
import { showToast } from "../../hooks/showToast";
import { NotificationType } from "../../types/INotification";
import { INotificationPayload } from "../../types/INotificationPayload";
import Fuse from "fuse.js";

const { Search } = Input;

export default function Cooperado() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [apenasAtivos, setApenasAtivos] = useState(true);
  const { data, isLoading, isError, refetch } = useQuery<ICooperado[]>(
    Rotas["/cooperado"],
    {
      enabled: apenasAtivos,
    }
  );
  const {
    data: dataInativos,
    isLoading: isLoadingInativos,
    isError: isErrorInativos,
    refetch: refetchInativos,
  } = useQuery<ICooperado[]>(Rotas["/cooperado"], {
    enabled: !apenasAtivos,
  });
  const [filteredData, setFilteredData] = useState<ICooperado[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const onDelete = useCallback(
    (id: string) => {
      async function callApiDelete() {
        const url = Rotas["/cooperado/:id"].replace(":id", id);
        await Api.delete(url);
        refetch();
        if (!apenasAtivos) {
          refetchInativos();
        }
      }
      showConfirmation({
        onConfirm: callApiDelete,
      });
    },
    [apenasAtivos, refetch, refetchInativos]
  );

  /*const onSort = useCallback(
    async (oldIndex: number, newIndex: number) => {
      if (!data) {
        return;
      }
      setLoading(true);
      const oldIndexGlobal = data[oldIndex].index;
      const newIndexGlobal = data[newIndex].index;
      await Api.post(Rotas["/cooperado/ordenar"], {
        oldIndex: oldIndexGlobal,
        newIndex: newIndexGlobal,
      });
      refetch();
      setLoading(false);
    },
    [data, refetch]
  );*/

  /*const sendNotification = useCallback(async (oferta: ICooperado) => {
    const payload = {
      tipo: NotificationType.Oferta,
      mensagem: oferta.nomeProduto,
      referenceId: oferta.id,
    } as INotificationPayload;

    if (oferta.notificarComImagem && oferta?.imagem?.url) {
      payload.imageUrl = oferta.imagem.url;
    }

    await Api.post(Rotas["/notificar"], payload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }, []);

  const sendNotificationText = useCallback(async (message: string) => {
    await Api.post(Rotas["/notificar"], {
      tipo: NotificationType.Oferta,
      mensagem: message,
    } as INotificationPayload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }, []);*/

  const onSearch = useCallback(
    (search: string) => {
      //const dataToFilter = apenasAtivos ? data : dataInativos;
      /*const dataToFilter = data;

      if (!dataToFilter) {
        return;
      }

      const fuse = new Fuse(dataToFilter, {
        keys: ["identificacao", "id"],
      });

      const result = fuse.search(search);
      setFilteredData(result.map((item) => item.item));

      setSearchTerm(search);*/
      const url = Rotas["/cooperado/pesquisar/:textSearch"].replace(":textSearch", search);
        Api.get(url).then((res) => {
          console.log(res.data);
          setFilteredData(res.data);
        }).finally(() => setSearchTerm(search));
    },
    [apenasAtivos, data, dataInativos]
  );

  const columns: ColumnsType<ICooperado> = [
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (_, item) => moment(item.createdAt).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Identificacao",
      dataIndex: "identificacao",
      key: "identificacao",
      width: "30%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
    },

    {
      title: "Ações",
      dataIndex: "options",
      key: "id",
      render: (_, item) => {
        return (
          <Space>
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => history.push(`/cooperado/edit/${item.id}`)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => onDelete(item.id)}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  if (isError || isErrorInativos) {
    return <p>Error</p>;
  }

  return (
    <div>
      <Row justify="space-around" gutter={[24, 24]}>
        <Col span={8}>
          
        </Col>
        <Col span={8}>
          
        </Col>
        <Col span={24}>
          <Search
            placeholder="pesquise por nome, detalhe ou código"
            onSearch={onSearch}
            enterButton
            value={searchTerm}
            onChange={(ev) => setSearchTerm(ev.target.value)}
          />
        </Col>

        <Col className="noselect" span={24}>
          {/*apenasAtivos && (
            <SortableTable
              loading={loading || isLoading || isLoadingInativos}
              data={filteredData.length > 0 ? filteredData : data ?? []}
              columns={columns}
              onSortEnd={onSort}
            />
          )*/}

          {//!apenasAtivos && 
          (
            <Table
              rowKey="id"
              loading={loading || isLoading || isLoadingInativos}
              dataSource={
                filteredData.length > 0 ? filteredData : dataInativos ?? []
              }
              columns={columns}
            />
          )}
        </Col>
      </Row>
      {/*modalOpen && (
        <NotificationModal
          additionalMessage="Exemplo: Confira já nossas ofertas do dia."
          onCloseModal={() => setModalOpen(false)}
          onOkModal={sendNotificationText}
        />
      )*/}
    </div>
  );
}
