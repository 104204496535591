import { Form, InputNumber, Button, Row, Col, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import DatePickerPtBr from "../../components/DatePickerPtBr";
import Spinner from "../../components/Spinner";
import useUploadFile from "../../components/UploadFile";
import Api, { Rotas } from "../../services/api";
import moment from "moment";
import { ContainerItem } from "./styles";
import { ICombustivel, ICombustivelData } from "../../types/ICombustivel";
import TabsWithChildren from "../../components/Tabs";
import { NotificationType } from "../../types/INotification";
import { INotificationPayload } from "../../types/INotificationPayload";
import { showToast } from "../../hooks/showToast";
import NotificationModal from "../../components/NotificationModal";

export default function Combustivel() {
  const { UploadFile, removeFilesOnApi, addFileToBeRemoved } = useUploadFile();

  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);

  const [selectedCity, setSelectedCity] =
    useState<keyof ICombustivelData>("InubiaPaulista");
  const [data, setData] = useState<ICombustivel>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      const request = await Api.get(Rotas["/combustivel"]);
      setData(request.data);
      setLoading(false);
    }
    getData();
  }, []);

  async function saveHandler() {
    try {
      const dataSource = form.getFieldsValue() as ICombustivelData;
      setLoading(true);
      const payload = dataSource[selectedCity as keyof ICombustivelData];

      const url = Rotas["/combustivel"];
      await Api.put(`${url}/${data?.id}`, {
        [selectedCity]: {
          ...payload,
          combustiveis: payload.combustiveis.map((comb) => ({
            ...comb,
            dataInicio: moment(comb.dataInicio).toDate(),
            dataFim: moment(comb.dataFim).toDate(),
            dataAlternativa: moment(comb.dataAlternativa).toDate(),
          })),
        },
      });
      removeFilesOnApi({ clearState: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const sendNotificationText = useCallback(async (message: string) => {
    await Api.post(Rotas["/notificar"], {
      tipo: NotificationType.Combustivel,
      mensagem: message,
    } as INotificationPayload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }, []);

  const { Title } = Typography;

  if (loading || !data) {
    return <Spinner />;
  }

  function getInitialValues() {
    const cloneData = JSON.parse(
      JSON.stringify(data?.data)
    ) as ICombustivelData;

    Object.keys(cloneData ?? {}).forEach((key) => {
      if (data) {
        cloneData[key as keyof ICombustivelData].combustiveis.forEach((c) => {
          c.dataInicio = moment(c.dataInicio);
          c.dataFim = moment(c.dataFim);
          c.dataAlternativa = moment(c.dataAlternativa);
        });

        cloneData[key as keyof ICombustivelData].combustiveisImagem.dataInicio =
          moment(
            cloneData[key as keyof ICombustivelData].combustiveisImagem
              .dataInicio
          );

        cloneData[key as keyof ICombustivelData].combustiveisImagem.dataFim =
          moment(
            cloneData[key as keyof ICombustivelData].combustiveisImagem.dataFim
          );

        cloneData[
          key as keyof ICombustivelData
        ].combustiveisImagem.dataAlternativa = moment(
          cloneData[key as keyof ICombustivelData].combustiveisImagem
            .dataAlternativa
        );
      }
    });

    return cloneData ?? {};
  }

  function onChangeTab(key: string) {
    saveHandler();
    setSelectedCity(key as keyof ICombustivelData);
  }

  const TabsSource = Object.keys(data?.data).map((key) => ({
    label: data.data[key as keyof ICombustivelData].cityName,
    key: key,
  }));

  const colProps = {
    xs: 24,
    sm: 12,
    md: 8,
    lg: 3,
    style: {
      display: "flex",
      placeContent: "center",
    },
  };

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Combustíveis</Title>
        </Col>
      </Row>
      <Row gutter={[12, 12]} align="middle" justify="end">
        <Col span={4}>
          <Button
            onClick={() => setModalOpen(true)}
            type="primary"
            size="large"
          >
            Enviar Notificações
          </Button>
        </Col>
      </Row>
      <TabsWithChildren
        tabs={TabsSource}
        onChange={onChangeTab}
        selectedTab={selectedCity}
      >
        <Row align="middle" justify="center">
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={getInitialValues()}
              size="middle"
            >
              {data.data[selectedCity]?.combustiveis.map((combustivel, idx) => {
                return (
                  <ContainerItem key={idx}>
                    <Title level={4}>{combustivel.name}</Title>
                    <Row justify="space-around" align="middle" gutter={[5, 5]}>
                      <Col {...colProps}>
                        <Form.Item
                          hidden
                          name={[selectedCity, "combustiveis", idx, `name`]}
                          initialValue={combustivel.name}
                        />
                        <Form.Item
                          label="Original"
                          name={[
                            selectedCity,
                            "combustiveis",
                            idx,
                            `valorOriginal`,
                          ]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber decimalSeparator="," />
                        </Form.Item>
                      </Col>

                      <Col {...colProps}>
                        <Form.Item
                          label="Promocao"
                          name={[
                            selectedCity,
                            "combustiveis",
                            idx,
                            `valorPromocao`,
                          ]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber decimalSeparator="," />
                        </Form.Item>
                      </Col>

                      <Col {...colProps}>
                        <Form.Item
                          label="Início"
                          name={[
                            selectedCity,
                            "combustiveis",
                            idx,
                            `dataInicio`,
                          ]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <DatePickerPtBr />
                        </Form.Item>
                      </Col>
                      <Col {...colProps}>
                        <Form.Item
                          label="Fim"
                          name={[selectedCity, "combustiveis", idx, `dataFim`]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <DatePickerPtBr />
                        </Form.Item>
                      </Col>

                      <Col flex="auto" {...colProps}>
                        <Form.Item
                          label="Preço Alternativo"
                          name={[
                            selectedCity,
                            "combustiveis",
                            idx,
                            `valorAlternativo`,
                          ]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber decimalSeparator="," />
                        </Form.Item>
                      </Col>

                      <Col {...colProps}>
                        <Form.Item
                          label="Data Alternativa"
                          name={[
                            selectedCity,
                            "combustiveis",
                            idx,
                            `dataAlternativa`,
                          ]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <DatePickerPtBr />
                        </Form.Item>
                      </Col>
                    </Row>
                  </ContainerItem>
                );
              })}

              <ContainerItem style={{ marginTop: 30 }}>
                <Title level={4}>Imagens</Title>
                <Row justify="center" gutter={[12, 12]}>
                  <Col span="10">
                    <Form.Item
                      name={[selectedCity, "combustiveisImagem", "imagem"]}
                      label="Imagem"
                    >
                      <UploadFile
                        onRemove={addFileToBeRemoved}
                        value={
                          data.data[selectedCity].combustiveisImagem.imagem
                        }
                        onSucess={(successFile) => {
                          form.setFieldsValue({
                            [selectedCity]: {
                              combustiveisImagem: {
                                imagem: successFile,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      name={[
                        selectedCity,
                        "combustiveisImagem",
                        "imagemPromocao",
                      ]}
                      label="Imagem Promoção"
                    >
                      <UploadFile
                        onRemove={addFileToBeRemoved}
                        value={
                          data.data[selectedCity].combustiveisImagem
                            .imagemPromocao
                        }
                        onSucess={(successFile) => {
                          form.setFieldsValue({
                            [selectedCity]: {
                              combustiveisImagem: {
                                imagemPromocao: successFile,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span="10">
                    <Form.Item
                      label="Início"
                      name={[selectedCity, "combustiveisImagem", `dataInicio`]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePickerPtBr />
                    </Form.Item>
                  </Col>
                  <Col span="10">
                    <Form.Item
                      label="Fim"
                      name={[selectedCity, "combustiveisImagem", `dataFim`]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePickerPtBr />
                    </Form.Item>
                  </Col>

                  <Col span="10">
                    <Form.Item
                      name={[
                        selectedCity,
                        "combustiveisImagem",
                        "imagemAlternativa",
                      ]}
                      label="Imagem Alternativa"
                    >
                      <UploadFile
                        onRemove={addFileToBeRemoved}
                        value={
                          data.data[selectedCity].combustiveisImagem
                            .imagemAlternativa
                        }
                        onSucess={(successFile) => {
                          form.setFieldsValue({
                            [selectedCity]: {
                              combustiveisImagem: {
                                imagemAlternativa: successFile,
                              },
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span="10">
                    <Form.Item
                      label="Data alternativa"
                      name={[
                        selectedCity,
                        "combustiveisImagem",
                        `dataAlternativa`,
                      ]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <DatePickerPtBr />
                    </Form.Item>
                  </Col>
                </Row>
              </ContainerItem>

              <Form.Item
                wrapperCol={{
                  style: {
                    alignItems: "center",
                    paddingTop: 20,
                  },
                }}
              >
                <Button type="primary" onClick={saveHandler}>
                  Salvar
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </TabsWithChildren>
      {modalOpen && (
        <NotificationModal
          additionalMessage="Exemplo: Confira as ofertas dos combustíveis."
          onCloseModal={() => setModalOpen(false)}
          onOkModal={sendNotificationText}
        />
      )}
    </div>
  );
}
