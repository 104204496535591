import { Form, Button, Row, Col, Typography } from "antd";
import moment from "moment";

import React, { useState } from "react";
import { useQuery } from "react-query";
import DatePickerPtBr from "../../components/DatePickerPtBr";

import Spinner from "../../components/Spinner";
import useUploadFile from "../../components/UploadFile";
import Api, { Rotas } from "../../services/api";
import { IImagemInicial } from "../../types/IImagemInicial";

export default function ImagemInicial() {
  const [form] = Form.useForm();
  const { UploadFile, removeFilesOnApi, addFileToBeRemoved } = useUploadFile();

  const { data, isLoading: isLoadingQuery, refetch } = useQuery<IImagemInicial>(
    Rotas["/imagem-inicial"]
  );

  const [loading, setLoading] = useState(false);

  async function onFinish(imagemInicial: IImagemInicial) {
    try {
      setLoading(true);

      await Api.put(
        Rotas["/imagem-inicial/:id"].replace(":id", data?.id ?? ""),
        {
          ...imagemInicial,
          dataInicio: moment(imagemInicial.dataInicio).toDate(),
          dataFim: moment(imagemInicial.dataFim).toDate(),
        }
      );
      removeFilesOnApi({ clearState: true });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      refetch();
    }
  }

  const colConfig = {
    xs: 20,
    md: 12,
  };
  const { Title } = Typography;

  if (loading || isLoadingQuery || !data) {
    return <Spinner />;
  }

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Imagem Inicial</Title>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col {...colConfig}>
          <Form
            initialValues={{
              ...data,
              dataInicio: moment(data.dataInicio),
              dataFim: moment(data.dataFim),
            }}
            form={form}
            layout="vertical"
            name="basic"
            onFinish={onFinish}
          >
            <Form.Item
              label={`Imagem`}
              name="imagem"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadFile
                onRemove={addFileToBeRemoved}
                value={data?.imagem}
                onSucess={(sucessFile) => {
                  form.setFieldsValue({
                    imagem: sucessFile,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label={`Imagem Promoção`}
              name="imagemPromocao"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadFile
                onRemove={addFileToBeRemoved}
                value={data?.imagemPromocao}
                onSucess={(sucessFile) => {
                  form.setFieldsValue({
                    imagemPromocao: sucessFile,
                  });
                }}
              />
            </Form.Item>

            <Row gutter={[12, 12]}>
              <Col span="12">
                <Form.Item
                  label="Início"
                  name="dataInicio"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePickerPtBr />
                </Form.Item>
              </Col>

              <Col span="12">
                <Form.Item
                  label="Fim"
                  name="dataFim"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePickerPtBr />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                {"Atualizar"}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
