import { Button, Col, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import { showConfirmation } from "../../hooks/showConfirmation";
import Api, { Rotas } from "../../services/api";
import { DeleteOutlined, EditOutlined, SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IReceita } from "../../types/IReceita";
import { useState } from "react";
import { showToast } from "../../hooks/showToast";
import { NotificationType } from "../../types/INotification";
import NotificationModal from "../../components/NotificationModal";
import { INotificationPayload } from "../../types/INotificationPayload";

export default function Receita() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { data, isLoading, isError, refetch } = useQuery<IReceita[]>(
    Rotas["/receita"]
  );

  function onDelete(userId: string) {
    async function callApiDelete() {
      setLoading(true);
      const url = Rotas["/receita/:id"].replace(":id", userId);
      await Api.delete(url);
      setLoading(false);
      refetch();
    }
    showConfirmation({
      onConfirm: callApiDelete,
    });
  }

  async function sendNotification(receita: IReceita) {
    const payload = {
      tipo: NotificationType.Receita,
      mensagem: receita.titulo,
      referenceId: receita.id,
    } as INotificationPayload;

    if (receita.notificarComImagem && receita.imagem.url) {
      payload.imageUrl = receita.imagem.url;
    }

    await Api.post(Rotas["/notificar"], payload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }

  async function sendNotificationText(message: string) {
    await Api.post(Rotas["/notificar"], {
      tipo: NotificationType.Receita,
      mensagem: message,
    } as INotificationPayload);
    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }

  const columns: ColumnsType<IReceita> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Título",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Descricao",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Ações",
      dataIndex: "options",
      key: "id",
      render: (_, item) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                showConfirmation({
                  onConfirm: () => sendNotification(item),
                });
              }}
              shape="circle"
              icon={<SoundOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => history.push(`/receitas/new-edit/${item.id}`)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => onDelete(item.id)}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  if (isError) {
    return <p>Error</p>;
  }

  return (
    <div>
      <Row justify="space-around" gutter={[24, 24]}>
        <Col span={8}>
          <Button
            onClick={() => history.push("/receitas/new-edit")}
            type="primary"
            size="large"
          >
            Criar novo
          </Button>
        </Col>
        <Col span={8}>
          <Button
            onClick={() => setModalOpen(true)}
            type="primary"
            size="large"
          >
            Enviar Notificações
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={loading || isLoading}
            dataSource={data ?? []}
            columns={columns}
          />
        </Col>
      </Row>
      {modalOpen && (
        <NotificationModal
          additionalMessage="Exemplo: Confira já nossas novidades."
          onCloseModal={() => setModalOpen(false)}
          onOkModal={sendNotificationText}
        />
      )}
    </div>
  );
}
