import { Button, Col, Row, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import { showConfirmation } from "../../hooks/showConfirmation";
import Api, { Rotas } from "../../services/api";
import { DeleteOutlined, EditOutlined, SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IInformativo } from "../../types/IInformativo";
import { useState } from "react";
import { NotificationType } from "../../types/INotification";
import { showToast } from "../../hooks/showToast";
import NotificationModal from "../../components/NotificationModal";
import { INotificationPayload } from "../../types/INotificationPayload";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../components/DatePickerPtBr";

export default function Informativo() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { data, isLoading, isError, refetch } = useQuery<IInformativo[]>(
    Rotas["/informativo/todos"]
  );

  function onDelete(userId: string) {
    async function callApiDelete() {
      setLoading(true);
      const url = Rotas["/informativo/:id"].replace(":id", userId);
      await Api.delete(url);
      refetch();
      setLoading(false);
    }
    showConfirmation({
      onConfirm: callApiDelete,
    });
  }

  async function sendNotification(informativo: IInformativo) {
    const payload = {
      tipo: NotificationType.Informativo,
      mensagem: informativo.descricao,
      referenceId: informativo.id,
    } as INotificationPayload;

    if (informativo.notificarComImagem && informativo?.imagem?.url) {
      payload.imageUrl = informativo.imagem.url;
    }

    await Api.post(Rotas["/notificar"], payload);
    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }

  async function sendNotificationText(message: string) {
    await Api.post(Rotas["/notificar"], {
      tipo: NotificationType.Informativo,
      mensagem: message,
    } as INotificationPayload);

    showToast("Notificação enviada com sucesso!", "success");
    setModalOpen(false);
  }

  const columns: ColumnsType<IInformativo> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Título",
      dataIndex: "titulo",
      key: "titulo",
    },
    {
      title: "Descricao",
      dataIndex: "descricao",
      key: "descricao",
    },
    {
      title: "Início",
      dataIndex: "dataInicio",
      key: "dataInicio",
      render: (_, item) => moment(item.dataInicio).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Fim",
      dataIndex: "dataFim",
      key: "dataFim",
      render: (_, item) => moment(item.dataFim).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Ações",
      dataIndex: "options",
      key: "id",
      render: (_, item) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                showConfirmation({
                  onConfirm: () => sendNotification(item),
                });
              }}
              shape="circle"
              icon={<SoundOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => history.push(`/informativos/new-edit/${item.id}`)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => onDelete(item.id)}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  if (isError) {
    return <p>Error</p>;
  }

  return (
    <div>
      <Row justify="space-around" gutter={[24, 24]}>
        <Col span={8}>
          <Button
            onClick={() => history.push("/informativos/new-edit")}
            type="primary"
            size="large"
          >
            Criar novo
          </Button>
        </Col>
        <Col span={8}>
          <Button
            onClick={() => setModalOpen(true)}
            type="primary"
            size="large"
          >
            Enviar Notificações
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={loading || isLoading}
            dataSource={data ?? []}
            columns={columns}
          />
        </Col>
      </Row>
      {modalOpen && (
        <NotificationModal
          additionalMessage="Exemplo: Confira nossas informações."
          onCloseModal={() => setModalOpen(false)}
          onOkModal={sendNotificationText}
        />
      )}
    </div>
  );
}
