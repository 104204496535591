import {
  Button,
  Col,
  Row,
  Form,
  TimePicker,
  DatePicker,
  Typography,
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import Api, { Rotas } from "../../services/api";
import { useEffect, useRef, useState } from "react";
import { IHorario, IHorarioAtendimento } from "../../types/IHorario";
import { showToast } from "../../hooks/showToast";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../components/DatePickerPtBr";
import { ExplanationText, TimeContainer } from "./styles";
const { Title } = Typography;
const timeFormat = "HH:mm";

const { RangePicker } = DatePicker;
export default function Receita() {
  const [data, setData] = useState<IHorarioAtendimento | undefined>();
  const originalData = useRef<IHorarioAtendimento>();
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<
    [moment.Moment, moment.Moment]
  >([moment(), moment()]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const request = await Api.get(Rotas["/horario-atendimento"]);
        setData(request.data);
        originalData.current = request.data;
      } catch (error) {
        showToast("Erro ao obter os horários, tente novamente mais tarde");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  function onChangeDatePicker(dates: [moment.Moment, moment.Moment]) {
    setSelectedDate(dates);
  }

  function onConfirmTime(values: {
    horaInicio: moment.Moment;
    horaFim: moment.Moment;
  }) {
    const horaInicioString = values.horaInicio.format(timeFormat);
    const horaFimString = values.horaFim.format(timeFormat);

    if (!data?.id || !data.data || !data.updatedAt) return;

    const diffDays = selectedDate[1].diff(selectedDate[0], "days") + 1;

    const firstDayCopy = moment(selectedDate[0]);
    let finalArray: IHorario[] = JSON.parse(JSON.stringify(data.data));
    for (let i = 0; i < diffDays; i++) {
      const currentDate = firstDayCopy.add(i === 0 ? 0 : 1, "days");

      const hasItemToUpdate = finalArray.some((d) =>
        moment(d.data).isSame(currentDate, "day")
      );

      if (hasItemToUpdate) {
        for (const info of finalArray) {
          if (moment(info.data).isSame(currentDate, "day")) {
            info.horaInicio = horaInicioString;
            info.horaFim = horaFimString;
          }
        }
      } else {
        const newItem: IHorario = {
          data: currentDate.toISOString(),
          horaInicio: horaInicioString,
          horaFim: horaFimString,
        };
        finalArray.push(newItem);
      }
    }

    const newData = {
      id: data.id,
      updatedAt: data.updatedAt,
      data: finalArray.sort(function (left, right) {
        return moment.utc(right.data).diff(moment.utc(left.data));
      }),
    };

    setData(newData);
  }

  function saveHandler() {
    const url = Rotas["/horario-atendimento/:id"].replace(
      ":id",
      data?.id ?? ""
    );
    Api.put(url, data?.data)
      .then(() => {
        showToast("Salvo com sucesso!", "success");
      })
      .catch(() => {
        showToast("Erro ao salvar, por favor tente mais tarde.");
      });
  }

  const columns: ColumnsType<IHorario> = [
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (_, item) => moment(item.data).format(DEFAULT_DATE_FORMAT),
    },
    {
      title: "Hora Início",
      dataIndex: "horaInicio",
      key: "horaInicio",
    },
    {
      title: "Hora Fim",
      dataIndex: "horaFim",
      key: "horaFim",
    },
  ];

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <TimeContainer>
            <Title level={4}>Selecionar datas</Title>

            <RangePicker
              format={DEFAULT_DATE_FORMAT}
              value={selectedDate}
              onChange={(v) => {
                const date1 = v?.[0];
                const date2 = v?.[1];

                if (!date1 || !date2) {
                  return;
                }

                onChangeDatePicker([date1, date2]);
              }}
            />
          </TimeContainer>
        </Col>
        <Col span={24}>
          <TimeContainer>
            <Title level={4}>Horários</Title>

            <Form name="time" onFinish={onConfirmTime}>
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <Form.Item
                    label="Hora Início"
                    name="horaInicio"
                    rules={[{ required: true }]}
                    initialValue={moment("2021-03-08T08:00:00.000Z")}
                  >
                    <TimePicker format={timeFormat} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Hora Fim"
                    name="horaFim"
                    rules={[{ required: true }]}
                    initialValue={moment("2021-03-08T20:30:00.000Z")}
                  >
                    <TimePicker format={timeFormat} />
                  </Form.Item>
                </Col>
                <Col
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  span={8}
                >
                  <Button type="primary" htmlType="submit">
                    Aplicar
                  </Button>
                  <ExplanationText>
                    Ao Aplicar, você irá adicionar/atualizar os horários dos
                    dias selecionados no período de data acima (apenas em tela)
                  </ExplanationText>
                </Col>
              </Row>
            </Form>
          </TimeContainer>
        </Col>
        <Col span={24}>
          <Table
            rowKey={(i) => i.data?.toString()}
            loading={loading}
            dataSource={data?.data ?? []}
            columns={columns}
          />
        </Col>
        <Col span={24}>
          <Button type="primary" onClick={saveHandler}>
            Gravar
          </Button>
        </Col>
      </Row>
    </div>
  );
}
