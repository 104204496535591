import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  Typography,
  Radio,
} from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DatePickerPtBr from "../../../components/DatePickerPtBr";
import Spinner from "../../../components/Spinner";
import useUploadFile from "../../../components/UploadFile";
import Api, { Rotas } from "../../../services/api";
import { ICooperado } from "../../../types/ICooperado";

interface ParamsProps {
  id: string;
}

export default function CooperadoEdit() {
  const { UploadFile, removeFilesOnApi, addFileToBeRemoved } = useUploadFile();

  const history = useHistory();
  const params = useParams<ParamsProps>();
  const [form] = Form.useForm();
  const ofertaId = params.id;
  const editing = !!ofertaId;
  const { data, isLoading: isLoadingQuery } = useQuery<ICooperado[]>(
    Rotas["/cooperado/pesquisar/:textSearch"].replace(":textSearch", ofertaId)
  );
  const [loading, setLoading] = useState(false);

  async function onFinish(oferta: ICooperado) {
    try {
      setLoading(true);
      if (editing) {
        await Api.put(Rotas["/cooperado/:id"].replace(":id", ofertaId), {
          ...oferta,
          //dataInicio: moment(oferta.dataInicio).toDate(),
          //dataFim: moment(oferta.dataFim).toDate(),
        });
      } else {
        await Api.post(Rotas["/cooperado"], {
          ...oferta,
          //dataInicio: moment(oferta.dataInicio).toDate(),
          //dataFim: moment(oferta.dataFim).toDate(),
        });
      }
      removeFilesOnApi({ clearState: false });
      history.push("/cooperado");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const colConfig = {
    xs: 20,
    md: 12,
  };
  const { Title } = Typography;

  if (loading || isLoadingQuery || !data) {
    return <Spinner />;
  }

  function getInitialValues() {
    if ((data?.length ?? 0) > 0 && data)
      return {
        ...data[0],
        //dataInicio: moment(data[0].dataInicio),
        //dataFim: moment(data[0].dataFim),
      };

    return {};
  }

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Cooperado</Title>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col {...colConfig}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={getInitialValues()}
            onFinish={onFinish}
          >
            <Form.Item
              label="Identificacao"
              name="identificacao"
              rules={[{ required: true, max: 50 }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Status da solicitação"
              name="status"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="pendente">Pendente</Radio>
                <Radio value="negado">Negado</Radio>
                <Radio value="aprovado">Aprovado</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
