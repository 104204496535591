import { useEffect, useState } from "react";
import { isLoggedIn, Login } from "./screens/Login";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./components/Spinner";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LayoutContainer } from "./components/LayoutContainer";
import { Menus } from "./services/menus";
import "react-confirm-alert/src/react-confirm-alert.css";
import { queryClient } from "./services/api";
import { ConfigProvider } from "antd";
import ptBR from "antd/lib/locale/pt_BR";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

function App() {
  const [authorized, setAuthorized] = useState<boolean | undefined>();

  useEffect(() => {
    setAuthorized(isLoggedIn());
  }, []);

  function onLogout() {
    sessionStorage.clear();
    setAuthorized(false);
  }

  if (authorized === undefined) {
    return <Spinner />;
  }

  if (authorized === false) {
    return (
      <>
        <Login onLoginSuccess={() => setAuthorized(true)} />
        <ToastContainer />
      </>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ptBR}>
        <Router>
          <LayoutContainer onLogout={onLogout}>
            <Switch>
              {Menus.map((m) => (
                <Route key={m.path} exact path={m.path}>
                  {m.Component}
                </Route>
              ))}
            </Switch>
          </LayoutContainer>
        </Router>
      </ConfigProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
