import { Layout, Menu, Breadcrumb, Button } from "antd";
import { useState } from "react";
import "./index.css";
import { getUser } from "../../screens/Login";
import { Menus } from "../../services/menus";
import { useHistory, useLocation } from "react-router-dom";

const { Content, Footer, Sider } = Layout;

interface LayoutContainerProps {
  children: JSX.Element;
  onLogout(): void;
}

export function LayoutContainer({ children, onLogout }: LayoutContainerProps) {
  const [collapsed, setCollapsed] = useState(false);

  const history = useHistory();
  const location = useLocation();

  function onCollapse(collapsed: boolean) {
    setCollapsed(collapsed);
  }

  function onClickMenu(e: any) {
    history.push(e.key as string);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        className="site-layout-background"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <div className="logo" />
        <Menu
          defaultSelectedKeys={[location.pathname]}
          onClick={onClickMenu}
          mode="inline"
        >
          {Menus.filter((m) => m.Icon).map((m) => (
            <Menu.Item key={m.path} icon={m.Icon}>
              {m.label}
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              Bem vindo {getUser()?.nome}!
              <Button type="link" onClick={onLogout}>
                Clique aqui para sair
              </Button>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          <b>Yes Dev</b> {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}
