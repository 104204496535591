import { Button, Col, Row } from "antd";
import { confirmAlert } from "react-confirm-alert";

interface ShowConfirmationProps {
  message?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}
export function showConfirmation({
  message = "Tem certeza que deseja fazer isso?",
  onConfirm,
  onCancel = () => {},
}: ShowConfirmationProps) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui ">
          <div
            style={{
              padding: "50px",
              backgroundColor: "#fff",
              boxShadow: "5px 5px 5px 2px grey",
            }}
          >
            <Row>
              <Col span={24}>
                <h1>Confirmação</h1>
                <p>{message}</p>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={() => {
                      onCancel();
                      onClose();
                    }}
                    danger
                    type="primary"
                    size={"large"}
                  >
                    Não
                  </Button>

                  <Button
                    onClick={() => {
                      onConfirm();
                      onClose();
                    }}
                    type="primary"
                    size={"large"}
                  >
                    Sim
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    },
  });
}
