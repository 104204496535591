import { Form, Input, Button, Row, Col, Typography, Radio } from "antd";
import moment from "moment";

import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DatePickerPtBr from "../../../components/DatePickerPtBr";

import Spinner from "../../../components/Spinner";
import useUploadFile from "../../../components/UploadFile";
import Api, { Rotas } from "../../../services/api";
import { IInformativo } from "../../../types/IInformativo";

interface ParamsProps {
  id: string;
}

export default function InformativoNewEdit() {
  const { UploadFile, removeFilesOnApi, addFileToBeRemoved } = useUploadFile();

  const history = useHistory();
  const params = useParams<ParamsProps>();
  const [form] = Form.useForm();
  const informativoId = params.id;
  const editing = !!informativoId;
  const { data, isLoading: isLoadingQuery } = useQuery<IInformativo[]>(
    Rotas["/informativo/pesquisar/:textSearch"].replace(
      ":textSearch",
      informativoId
    )
  );
  const [loading, setLoading] = useState(false);

  async function onFinish(informativo: IInformativo) {
    try {
      setLoading(true);
      if (editing) {
        await Api.put(Rotas["/informativo/:id"].replace(":id", informativoId), {
          ...informativo,

          dataInicio: moment(informativo.dataInicio).toDate(),
          dataFim: moment(informativo.dataFim).toDate(),
        });
      } else {
        await Api.post(Rotas["/informativo"], {
          ...informativo,
          dataInicio: moment(informativo.dataInicio).toDate(),
          dataFim: moment(informativo.dataFim).toDate(),
        });
      }
      removeFilesOnApi({ clearState: false });
      history.push("/informativos");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const colConfig = {
    xs: 20,
    md: 12,
  };
  const { Title } = Typography;

  if (loading || isLoadingQuery) {
    return <Spinner />;
  }

  function getInitialValues() {
    if ((data?.length ?? 0) > 0 && data)
      return {
        ...data[0],
        dataInicio: moment(data[0].dataInicio),
        dataFim: moment(data[0].dataFim),
      };

    return {};
  }

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Informativo</Title>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col {...colConfig}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={getInitialValues()}
            onFinish={onFinish}
          >
            <Form.Item
              label={`Imagem`}
              name="imagem"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadFile
                onRemove={addFileToBeRemoved}
                initialValue={
                  data && data.length > 0 ? data[0].imagem : undefined
                }
                onSucess={(sucessFile) => {
                  form.setFieldsValue({
                    imagem: sucessFile,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Título"
              name="titulo"
              rules={[{ required: true, max: 70 }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Mensagem"
              name="descricao"
              rules={[{ required: true, max: 224 }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Notificar com imagem?"
              name="notificarComImagem"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>

            <Title level={2}>Duração</Title>
            <Row gutter={[12, 12]}>
              <Col span="12">
                <Form.Item
                  label="Início"
                  name="dataInicio"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePickerPtBr />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Fim"
                  name="dataFim"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePickerPtBr />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
