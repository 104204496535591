import {
  UserOutlined,
  ShoppingCartOutlined,
  SoundOutlined,
  ToolOutlined,
  SwitcherOutlined,
  ClockCircleOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import UsuarioScreen from "../screens/Usuario";
import UsuarioNewEditScreen from "../screens/Usuario/NewEdit";
import OfertaScreen from "../screens/Oferta";
import OfertaNewEditScreen from "../screens/Oferta/NewEdit";
import CombustivelScreen from "../screens/Combustivel";
import InformativoScreen from "../screens/Informativo";
import InformativoNewScreen from "../screens/Informativo/NewEdit";
import ReceitaScreen from "../screens/Receita";
import ReceitaNewScreen from "../screens/Receita/NewEdit";
import HorarioFuncionamentoScreen from "../screens/HorarioFuncionamento";
import ImagemInicialScreen from "../screens/ImagemInicial";
import HomeScreen from "../screens/Home";
import CooperadoScreen from "../screens/Cooperado";
import CooperadoEditScreen from "../screens/Cooperado/NewEdit";

export const Menus = [
  {
    path: "/",
    label: "Home",
    Icon: <HomeOutlined />,
    Component: <HomeScreen />,
  },
  {
    path: "/usuarios",
    label: "Usuários",
    Icon: <UserOutlined />,
    Component: <UsuarioScreen />,
  },
  {
    path: "/usuarios/new-edit/:id?",
    label: "Usuários",
    Component: <UsuarioNewEditScreen />,
  },
  {
    path: "/ofertas",
    label: "Ofertas",
    Icon: <ShoppingCartOutlined />,
    Component: <OfertaScreen />,
  },
  {
    path: "/ofertas/new-edit/:id?",
    label: "Ofertas",
    Component: <OfertaNewEditScreen />,
  },
  {
    path: "/combustiveis",
    label: "Combustíveis",
    Icon: <ToolOutlined />,
    Component: <CombustivelScreen />,
  },
  {
    path: "/informativos",
    label: "Informativos",
    Icon: <SoundOutlined />,
    Component: <InformativoScreen />,
  },
  {
    path: "/informativos/new-edit/:id?",
    label: "Informativos",
    Component: <InformativoNewScreen />,
  },
  {
    path: "/receitas",
    label: "Dicas e Receitas",
    Icon: <SwitcherOutlined />,
    Component: <ReceitaScreen />,
  },
  {
    path: "/receitas/new-edit/:id?",
    label: "Dicas e Receitas",
    Component: <ReceitaNewScreen />,
  },
  {
    path: "/horario-de-funcionamento",
    label: "Horário de Funcionamento",
    Icon: <ClockCircleOutlined />,
    Component: <HorarioFuncionamentoScreen />,
  },
  {
    path: "/imagem-inicial",
    label: "Imagem Inicial",
    Icon: <HomeOutlined />,
    Component: <ImagemInicialScreen />,
  },
  {
    path: "/cooperado",
    label: "Cooperados",
    Icon: <UsergroupAddOutlined />,
    Component: <CooperadoScreen />,
  },
  {
    path: "/cooperado/edit/:id",
    label: "Cooperado",
    Component: <CooperadoEditScreen />,
  },
];
