import { Statistic, Row, Col } from "antd";
import { LikeOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { Rotas } from "../../services/api";

export default function Home() {
  const { data, isLoading } = useQuery<string>(Rotas["/token-total"]);

  return (
    <Row gutter={16}>
      <Col span={12}>
        <Statistic
          loading={isLoading}
          title="Instalações"
          value={data}
          prefix={<LikeOutlined />}
        />
      </Col>
    </Row>
  );
}
