import { Tabs } from "antd";
const { TabPane } = Tabs;

interface TabsProps {
  selectedTab: string;
  onChange(tab: string): void;
  tabs: { label: string; key: string }[];
  children: JSX.Element;
}

export default function TabsWithChildren({
  onChange,
  selectedTab,
  tabs,
  children,
}: TabsProps) {
  return (
    <Tabs
      defaultActiveKey={selectedTab}
      activeKey={selectedTab}
      onChange={onChange}
    >
      {tabs.map((tab) => (
        <TabPane tab={tab.label} key={tab.key}>
          {children}
        </TabPane>
      ))}
    </Tabs>
  );
}
