import { Button, Upload, UploadProps } from "antd";
import { useState } from "react";
import { showToast } from "../../hooks/showToast";
import { Image, ImageTitleContainer } from "./styles";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Api from "../../services/api";
import Spinner from "../Spinner";
import { IImagem } from "../../types/IImagem";
import { useCallback } from "react";

interface UploadFileProps {
  onSucess?(file: IImagem): void;
  onRemove(file: IImagem): void;
  preview?: boolean;
  initialValue?: IImagem;
  value?: IImagem;
}

type RemoveFilesOnApiProps = {
  clearState: boolean;
};

const UploadFile = ({
  initialValue,
  value,
  preview = true,
  onSucess,
  onRemove,
}: UploadFileProps) => {
  const [file, setFile] = useState<IImagem | undefined>(initialValue ?? value);
  const [loading, setLoading] = useState(false);

  async function onRemoveHandler() {
    setLoading(true);
    try {
      if (file) {
        onRemove(file);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setFile(undefined);
    }
  }

  function onUploadSuccess(fileUploaded: IImagem) {
    const file = {
      url: fileUploaded.url,
      key: fileUploaded.key,
      bucket: fileUploaded.bucket,
      tag: fileUploaded.tag,
    };
    onSucess && onSucess(file);
    setFile(file);
  }
  function onUploadFailure() {
    showToast("Erro ao concluir upload de arquivo");
  }

  const uploadProps: UploadProps = {
    showUploadList: false,
    beforeUpload: (file) => {
      setLoading(true);
      file.arrayBuffer().then((fileBuffer) => {
        const data = new FormData();

        data.append("file", new Blob([fileBuffer]));

        Api.post<IImagem>("file", data, {
          headers: {
            "Content-Type": "multipart/form-data",
            filename: file.name,
          },
        })
          .then((data) => {
            onUploadSuccess(data.data);
          })
          .catch(() => {
            onUploadFailure();
          })
          .finally(() => {
            setLoading(false);
          });
      });
      return false;
    },
  };

  return (
    <div>
      {loading && <Spinner />}
      <ImageTitleContainer>
        <Upload {...uploadProps}>
          <Button disabled={!!file?.url} icon={<UploadOutlined />}>
            Upload
          </Button>
        </Upload>

        <Button
          disabled={!file?.url}
          icon={<DeleteOutlined />}
          onClick={onRemoveHandler}
        />
      </ImageTitleContainer>

      {file && preview && <Image src={file.url} />}
    </div>
  );
};

export default function useUploadFile() {
  const [filesToRemove, setFilesToRemove] = useState<IImagem[]>([]);

  function removeFilesOnApi({ clearState = false }: RemoveFilesOnApiProps) {
    for (const file of filesToRemove) {
      try {
        Api.delete("file", {
          data: file,
        });
      } catch {}
    }

    if (clearState) {
      setFilesToRemove([]);
    }
  }

  const addFileToBeRemoved = useCallback((file: IImagem) => {
    setFilesToRemove((prev) => [...prev, file]);
  }, []);

  return {
    UploadFile,
    removeFilesOnApi,
    addFileToBeRemoved,
  };
}
