import { Modal, Button, Input, Typography } from "antd";
import { useState } from "react";

const { Title } = Typography;

interface NotificationModalProps {
  onCloseModal(): void;
  onOkModal(message: string): void;
  additionalMessage?: string;
}

export default function NotificationModal({
  onCloseModal,
  onOkModal,
  additionalMessage,
}: NotificationModalProps) {
  const [message, setMessage] = useState("");

  return (
    <Modal
      visible={true}
      title="Notificações"
      onOk={() => onOkModal(message)}
      onCancel={onCloseModal}
      footer={[
        <Button key="back" onClick={onCloseModal}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={() => onOkModal(message)}>
          Enviar
        </Button>,
      ]}
    >
      <Title level={4}>
        Você pode enviar uma notificação simples com o objetivo de informar os
        usuários.
      </Title>
      <Title level={5}>{additionalMessage}</Title>
      <Input.TextArea
        maxLength={240}
        value={message}
        onChange={(ev) => setMessage(ev.target.value)}
      />
    </Modal>
  );
}
