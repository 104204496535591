import styled from "styled-components";

export const Image = styled.img`
  width: 300px;
  padding-top: 10px;
`;

export const ImageTitleContainer = styled.div`
  display: flex;
`;
