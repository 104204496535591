import Axios from "axios";
import { QueryClient } from "react-query";
import { getToken } from "../screens/Login";

const isDev = false;
const API_URL = isDev
  ? "http://localhost:3434/api"
  : "https://app.cocipa.com.br/api";

const Api = Axios.create({
  baseURL: API_URL,
  headers: {
    authorization: getToken(),
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => {
        const request = await Api.get(String(queryKey[0] || ""));

        return request.data;
      },
    },
  },
});

export default Api;

export enum Rotas {
  "/usuario" = "/usuario",
  "/usuario/login" = "/usuario/login",
  "/usuario/:id" = "/usuario/:id",
  "/usuario/pesquisar/:textSearch" = "/usuario/pesquisar/:textSearch",

  "/oferta" = "/oferta",
  "/oferta/ativos" = "/oferta/ativos",
  "/oferta/inativos" = "/oferta/inativos",
  "/oferta/ordenar" = "/oferta/ordenar",
  "/oferta/:id" = "/oferta/:id",
  "/oferta/pesquisar/:textSearch" = "/oferta/pesquisar/:textSearch",

  "/combustivel" = "/combustivel",
  "/combustivel/:id" = "/combustivel/:id",

  "/informativo" = "/informativo",
  "/informativo/todos" = "/informativo/todos",
  "/informativo/:id" = "/informativo/:id",
  "/informativo/pesquisar/:textSearch" = "/informativo/pesquisar/:textSearch",

  "/receita" = "/receita",
  "/receita/:id" = "/receita/:id",
  "/receita/pesquisar/:textSearch" = "/receita/pesquisar/:textSearch",

  "/horario-atendimento" = "/horario-atendimento",
  "/horario-atendimento/:id" = "/horario-atendimento/:id",

  "/imagem-inicial" = "/imagem-inicial",
  "/imagem-inicial/:id" = "/imagem-inicial/:id",

  "/notificar" = "/notificar",
  "/token-total" = "/token-total",

  "/cooperado" = "/cooperado",
  "/cooperado/:id" = "/cooperado/:id",
  "/cooperado/pesquisar/:textSearch" = "/cooperado/pesquisar/:textSearch",
}
