import styled from "styled-components";

export const TimeContainer = styled.div`
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 20px;
`;

export const ExplanationText = styled.p`
  font-size: 12px;
  padding-top: 10px;
`;
