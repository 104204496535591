import { Button, Col, Row, Table, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import Spinner from "../../components/Spinner";
import { showConfirmation } from "../../hooks/showConfirmation";
import Api, { Rotas } from "../../services/api";
import { IUser } from "../../types/IUser";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export default function Usuario() {
  const history = useHistory();
  const { data, isLoading, isError, refetch } = useQuery<IUser[]>(
    Rotas["/usuario"]
  );

  function onDelete(userId: string) {
    async function callApiDelete() {
      const url = Rotas["/usuario/:id"].replace(":id", userId);
      await Api.delete(url);
      refetch();
    }
    showConfirmation({
      onConfirm: callApiDelete,
    });
  }

  const columns: ColumnsType<IUser> = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ações",
      dataIndex: "options",
      key: "id",
      render: (_, item) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => history.push(`/usuarios/new-edit/${item.id}`)}
              shape="circle"
              icon={<EditOutlined />}
            />
            <Space />
            <Space />
            <Button
              type="primary"
              onClick={() => onDelete(item.id)}
              danger
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Button
            onClick={() => history.push("/usuarios/new-edit")}
            type="primary"
            size="large"
          >
            Criar novo
          </Button>
        </Col>
        <Col span={24}>
          <Table rowKey="id" dataSource={data} columns={columns} />
        </Col>
      </Row>
    </div>
  );
}
