import { Form, Input, Button, Row, Col, Typography } from "antd";
import { showToast } from "../../hooks/showToast";
import Api, { Rotas } from "../../services/api";
const { Title } = Typography;

export function getToken() {
  return window.sessionStorage.getItem("t");
}
export function getUser() {
  const userString = window.sessionStorage.getItem("u");
  if (!userString) {
    return null;
  }
  return JSON.parse(userString);
}
export function isLoggedIn() {
  return !!getToken();
}

interface LoginProps {
  onLoginSuccess(): void;
}

export const Login = ({ onLoginSuccess }: LoginProps) => {
  const onFinish = async ({ email, senha }: any) => {
    try {
      const response = await Api.post<any>(Rotas["/usuario/login"], {
        email,
        senha,
      });
      window.sessionStorage.setItem("t", response.data.token);
      window.sessionStorage.setItem("u", JSON.stringify(response.data));
      Api.defaults.headers = {
        authorization: response.data.token,
      };
      onLoginSuccess();
    } catch (error) {
      showToast("Dados de acesso inválidos");
    }
  };

  const colConfig = {
    xs: 20,
    md: 12,
  };
  return (
    <>
      <Row
        gutter={[12, 12]}
        style={{ height: "15vh" }}
        align="middle"
        justify="center"
      >
        <Col>
          <Title level={2}>Acesso ao sistema administrativo (App)</Title>
        </Col>
      </Row>
      <Row
        gutter={[12, 12]}
        style={{ height: "10vh" }}
        align="middle"
        justify="center"
      >
        <Col>
          <img src="cocipa.jpg" alt="cocipa" style={{ height: "100px" }} />
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ height: "40vh" }}>
        <Col {...colConfig}>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: "Informe seu e-mail" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="senha"
              rules={[{ required: true, message: "Informe sua senha" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
