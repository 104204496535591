import { Form, Input, Button, Row, Col, Typography, Radio } from "antd";

import React, { useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";

import Spinner from "../../../components/Spinner";
import useUploadFile from "../../../components/UploadFile";
import Api, { Rotas } from "../../../services/api";
import { IReceita } from "../../../types/IReceita";

interface ParamsProps {
  id: string;
}

export default function ReceitaNewEdit() {
  const { UploadFile, removeFilesOnApi, addFileToBeRemoved } = useUploadFile();

  const history = useHistory();
  const params = useParams<ParamsProps>();
  const [form] = Form.useForm();
  const receitaId = params.id;
  const editing = !!receitaId;
  const { data, isLoading: isLoadingQuery } = useQuery<IReceita[]>(
    Rotas["/receita/pesquisar/:textSearch"].replace(":textSearch", receitaId)
  );
  const [loading, setLoading] = useState(false);

  async function onFinish(receita: IReceita) {
    try {
      setLoading(true);
      if (editing) {
        await Api.put(Rotas["/receita/:id"].replace(":id", receitaId), {
          ...receita,
        });
      } else {
        await Api.post(Rotas["/receita"], {
          ...receita,
        });
      }
      removeFilesOnApi({ clearState: false });
      history.push("/receitas");
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const colConfig = {
    xs: 20,
    md: 12,
  };
  const { Title } = Typography;

  if (loading || isLoadingQuery) {
    return <Spinner />;
  }

  function getInitialValues() {
    if ((data?.length ?? 0) > 0 && data) return data[0];

    return {};
  }

  return (
    <div>
      <Row gutter={[12, 12]} align="middle" justify="center">
        <Col>
          <Title level={2}>Receita</Title>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col {...colConfig}>
          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={getInitialValues()}
            onFinish={onFinish}
          >
            <Form.Item
              label={`Imagem`}
              name="imagem"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UploadFile
                onRemove={addFileToBeRemoved}
                initialValue={
                  data && data.length > 0 ? data[0].imagem : undefined
                }
                onSucess={(sucessFile) => {
                  form.setFieldsValue({
                    imagem: sucessFile,
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Título"
              name="titulo"
              rules={[{ required: true, max: 70 }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Mensagem"
              name="descricao"
              rules={[{ max: 1000 }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              label="Notificar com imagem?"
              name="notificarComImagem"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={true}>Sim</Radio>
                <Radio value={false}>Não</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                style: {
                  alignItems: "center",
                },
              }}
            >
              <Button type="primary" htmlType="submit">
                Salvar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
