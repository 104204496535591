import styled from "styled-components";

export const ContainerItem = styled.div`
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  & > .ant-typography {
    margin-left: 10px;
    padding-top: 10px;
  }
`;
